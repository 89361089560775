import { useEffect, useState } from 'react'
import { Button, Divider, Input } from 'antd'
import styles from './OwnedStamps.module.css'

require('dotenv').config()

const COLLECTION_SLUG = 'weedstamps'

const EditStamp = ({ imgSrc, name, slug }) => {
  return (
    <li key={slug} className={styles.asset}>
      <img src={imgSrc} className={styles.image} alt={name} />
      <div>
        <h3 className={styles.title}>{name}</h3>
        <h4>QR Code URL: https://weedstamps.com/s/{slug}</h4>
        <Input
          className={styles['edit-input']}
          size='large'
          addonBefore='Redirects to:'
          addonAfter={<Button type='text' disabled>Save</Button>}
          defaultValue={`https://www.weedmaps.com/strain/${slug}`}
          allowClear
          disabled
        />
      </div>
    </li>
  )
}

const getSlug = traits =>
  traits.find(({ trait_type }) => trait_type === '_ID').value.toLowerCase()

const OwnedStamps = ({ account }) => {
  const [stamps, setStamps] = useState(null)
  const apiUrl = `https://api.opensea.io/api/v1/assets?owner=${account}&order_direction=desc&offset=0&limit=100&collection=${COLLECTION_SLUG}`

  useEffect(
    () =>
      fetch(apiUrl)
        .then(res => res.json())
        // .then(({ assets }) => setStamps(assets))
        .then(({ assets }) => {
          const stampList = assets.map(
            ({ image_url: imgSrc, name, traits }) => (
              <EditStamp
                key={getSlug(traits)}
                imgSrc={imgSrc}
                name={name}
                slug={getSlug(traits)}
              />
            )
          )
          setStamps(stampList)
        })
        .catch(err => console.error(err)),
    [apiUrl]
  )

  const content = stamps && stamps.length > 0
    ? <ol>{stamps}</ol>
    : <span className={styles['no-stamps']}>¯\_(ツ)_/¯ You don't own any stamps.</span>

  return (
    <section className={styles.root}>
      <Divider orientation="left" plain>
        Your Weedstamps
      </Divider>
      {stamps && content}
    </section>
  )
}

export default OwnedStamps
