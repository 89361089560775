import { useEffect, useState } from 'react'
import { Card, Descriptions } from 'antd'
import styles from './StrainViewer.module.css'

const { Meta } = Card
const terpenes = {
  bisabolol: '255, 212, 0',
  borneol: '255, 192, 0',
  camphene: '255, 173, 0',
  caryophyllene: '255, 141, 0',
  'delta-3-carene': '255, 64, 0',
  eucalyptol: '255, 0 2',
  geraniol: '208, 0, 91',
  humulene: '160, 16, 148',
  limonene: '142, 39, 205',
  linalool: '120, 56, 255',
  myrcene: '45, 110, 248',
  pinene: '0, 165, 226',
  phytol: '0, 185, 194',
  terpinolene: '0, 204, 159',
  'trans-nerolidol': '38, 208, 91',
  valancene: '164, 210, 0'
}

const StrainViewer = ({ strain }) => {
  const [strainData, setStrainData] = useState({})
  const imgSrc = `https://s3.amazonaws.com/weedstamps.com/stamps/${strain}.svg`
  const dataUrl = `https://cannabis-data.s3.amazonaws.com/${strain}.json`
  const { aka, effects = {}, flavors = {}, name, terps = [] } = strainData
  const effectList  = Object.keys(effects)
    .sort((a, b) => effects[b].votes - effects[a].votes)
    .map(k => k)
    .splice(0, 3)
    .map(item => <li key={item}>{item}</li>)
  const flavorList = Object.keys(flavors)
    .sort((a, b) => flavors[b].votes - flavors[a].votes)
    .map(k   => k)
    .splice(0, 3)
    .map(item => <li key={item}>{item}</li>)
  const terpList = terps
    .sort((a, b) => b.score - a.score)
    .slice(0, 3)
    .map(t => t.name.toLowerCase())
    .map(item => <li key={item} className={styles.terpene} style={{ color: `rgb(${terpenes[item]})` }}>{item}</li>)

  useEffect(() => fetch(dataUrl)
    .then(_ => _.json())
    .then(data => setStrainData(data)), [dataUrl])

  return strain
    ? (
      <Card
        className={styles.root}
        cover={<img alt="example" src={imgSrc} className={styles.image} />}
      >
        <h2 className={styles.title}>{name}</h2>
        {aka &&
          <Meta title={<span className="ant-descriptions-title">A.K.A.</span>} description={aka} />
        }
        <Descriptions className={styles.properties} title="Properties" layout="vertical" bordered>
          <Descriptions.Item label="Terpenes">{terpList}</Descriptions.Item>
          <Descriptions.Item label="Flavors">{flavorList}</Descriptions.Item>
          <Descriptions.Item label="Effects">{effectList}</Descriptions.Item>
        </Descriptions>

      </Card>
    )
    : null
}

export default StrainViewer
