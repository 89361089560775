import { useEffect, useState } from 'react'
import { Button, Divider, Layout, PageHeader, Typography } from 'antd'
import OwnedStamps from './components/OwnedStamps'
import StrainSelector from './components/StrainSelector'
import StrainViewer from './components/StrainViewer'
import styles from './App.module.css'
import "antd/dist/antd.css"

const { Footer, Content } = Layout
const { Text } = Typography

const App = () => {
  const [isMetaMaskConnected, setIsMetaMaskConnected] = useState(typeof window.ethereum !== 'undefined')
  const [account, setAccount] = useState(null)
  const [strain, setStrain] = useState(window.location.hash.substr(1) || 'original-glue')

  const getAccount = () => {
    if (!isMetaMaskConnected) return setAccount(null)
    window.ethereum.request({ method: 'eth_requestAccounts' })
      .then(accounts => setAccount(accounts[0]))
  }

  useEffect(() =>
    setIsMetaMaskConnected(typeof window.ethereum !== 'undefined'), 
    [setIsMetaMaskConnected]
  )

  useEffect(getAccount, [isMetaMaskConnected])

  useEffect(() => {
    window.ethereum.on('accountsChanged', accounts => setAccount(accounts[0]))
    window.addEventListener('hashchange', () => setStrain(window.location.hash.substr(1)))
  }, [])

  return (
    <Layout className={styles.layout}>
      <PageHeader
        title={<h1><img src="./logo.png" width="34" alt="logo" /> WeedStamps</h1>}
        extra={[
          !!account
            ? <Text className={styles.account} key="metamask-account">Connected: {account}</Text>
            : <Button key="metamask-button" onClick={getAccount}>Connect Metamask</Button>
        ]}
      />
      <Content className={styles.content}>
        { account  && <>
          <OwnedStamps account={account} />
        </>}
        <Divider orientation="left" plain>
          All Weedstamps
        </Divider>

        <StrainSelector onChange={setStrain} defaultValue={strain} />
        <StrainViewer strain={strain} />
      </Content>
      <Footer>&copy; {new Date().getFullYear()} Horace Nelson</Footer>
    </Layout>
  )
  
}
export default App
