import { Select } from 'antd'
import data from '../_manifest.json'
import styles from './StrainSelector.module.css'

const { Option } = Select

const SelectStrain = ({ defaultValue, onChange }) => {
  const Options = data.sort().map(strain => <Option key={strain} value={strain}>{strain.replace(/-/gm, ' ')}</Option>)

  function onSearch (val) {
    console.log('search:', val)
  }

  return (
    <Select
      className={styles.root}
      defaultValue={defaultValue}
      filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      onChange={onChange}
      onSearch={onSearch}
      optionFilterProp='children'
      placeholder='Select a Strain'
      showSearch
      value={defaultValue}
    >
      {Options}
    </Select>
  )
}

export default SelectStrain
